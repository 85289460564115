import React from "react";

const ParagraphPartenaire = ({
  item: { id, lien_partenaire, logo_partenaire },
}) => {
  return (
    <div key={"img" + id} className="col-5 col-sm-3 col-md-2 logo-partenaire">
      <a href={lien_partenaire} rel="noreferrer" target="_blank">
        <img alt="img" src={process.env.REACT_APP_URL_BACK + logo_partenaire} />
      </a>
    </div>
  );
};

export default ParagraphPartenaire;
