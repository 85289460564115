import React, { useState } from "react";
import "./styles/App.scss";
import WebPages from "./webpages";
import Footer from "./components/Footer";

function App() {
  
  return (
    <div className="App">{<WebPages />}
    </div>
  );
}

export default App;
