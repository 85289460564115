import React from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import styles from "styles/Layout.module.scss";
import Footer from "./Footer";
import PartenairesList from "./PartenairesList";
import ScrollArrow from "./ScrollArrow";

const Layout = ({children, items, ...props}) => {
    const path = props.location.pathname;

    return (
        <>
            <Header/>
            {path === "/" ? <main className={styles.WrapperHomepage + ' ' + styles.topLayoutHomepage}>
                <SideMenu items={items} {...props} />
                <div className={styles.Main}>{children}</div>
            </main> : <main className={styles.Wrapper + ' ' + styles.topLayout}>
                <SideMenu items={items} {...props} />
                <div className={styles.Main}>{children}</div>
            </main>}
            <PartenairesList/>
            <ScrollArrow/>
            <Footer/>
        </>
    );
};

export default Layout;
