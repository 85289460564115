import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { ApiClient } from "utils/apiClient";
import Button from "@mui/material/Button";
import { AutoLink } from "components/AutoLink";
import "styles/evenements.scss";

export function Evenements(props) {
  const api = ApiClient();

  const [evenements, setEvenements] = useState([]);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    api.getEvenements(props.type).then((response) => {
      setEvenements(response.data);
    });
  };

  
  return evenements.map((item, i) =>
      <OldEvenements key={i} item={item} />
  );

}


function OldEvenements(props) {
  return (
    <div key={props.item.nid} className="evenements">
      <h2>{props.item.title} </h2>
      <div className="list-evenements">
        <div className="col-sm-2">
          <img
            alt="img"
            src={process.env.REACT_APP_URL_BACK + props.item.image_actu}
          />
        </div>
        <div className="evenement-body col-sm-10">
          <span className="date-ev">{props.item.date_evenement_export}</span>
          <div
            dangerouslySetInnerHTML={{
              __html:
                props.item.body.length > 350
                  ? props.item.body.substring(0, 350) + "..."
                  : props.item.body,
            }}></div>

          <AutoLink link={"/actualites/" + props.item.nid}>
            <Button size="small">En savoir +</Button>
          </AutoLink>
        </div>
      </div>
    </div>
  );
}
