import React, { useState, useEffect } from "react";
import { ApiClient } from "utils/apiClient";
import "styles/Consultations.scss";
import Button from "@mui/material/Button";
import { AutoLink } from "components/AutoLink";

export function Consultations() {
  const api = ApiClient();

  const [consultations, setConsultations] = useState([]);

  useEffect(() => {
    fetchConsultations();
  }, []);

  const fetchConsultations = async () => {
    api.getConsultations().then((response) => {
      setConsultations(response.data);
    });
  };

  return (
    <>
      {consultations.map(
        (item, i) => i < 4 && <Consultation key={i} consultation={item} />
      )}
      <div className="voirplus">
        <AutoLink link={"/communication/consultations"}>
          <Button size="small">Voir plus</Button>
        </AutoLink>
      </div>
    </>
  );
}

function Consultation(props) {
  return (
    <div className="consultation d-flex row">
      <div className="d-flex date-consultation col-2 col-sm-2 col-md-offset-2 col-md-3 col-lg-4 col-xl-3">
        <span className="jour">{props.consultation.jour}</span>
        <span className="d-flex flex-column mois-annee">
          <span>{props.consultation.mois}</span>
          <span>{props.consultation.annee}</span>
        </span>
      </div>
      <div className="d-flex flex-column content col-10 col-md-7 col-lg-8 col-xl-9">
        <span className="titre">{props.consultation.title}</span>
        <span
          className="description"
          dangerouslySetInnerHTML={{
            __html:
              props.consultation.body.length > 340
                ? props.consultation.body.substring(0, 340) + "..."
                : props.consultation.body,
          }}
        />
      </div>
    </div>
  );
}
