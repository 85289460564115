import { NavLink } from "react-router-dom";
// import { HashLink } from 'react-router-hash-link';


export function AutoLink({ link, children }) {
    if (link && link.includes("#")) return <a href={link}>{children}</a>;
    else return <NavLink activeClassName='is-active' to={link} /*state="test"*/>{children}</NavLink>;
}



