import React from "react";

const ParagraphTexte = ({ item: { id, body, image } }) => {
  return (
    <div
      className="col-md-12 mb-5 paragraphTexteOnImage"
      key={"paragraph" + id}
      // style={{backgroundImage: `url(${process.env.REACT_APP_URL_BACK+image[0]})`, backgroundPosition: 'center',
      style={{
        backgroundImage: `url(https://picsum.photos/1905/308)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}>
      <div
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
    </div>
  );
};

export default ParagraphTexte;
