import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ApiClient } from "utils/apiClient";
import "styles/Actus.scss";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AutoLink } from "components/AutoLink";
import { SpaceBarOutlined } from "@mui/icons-material";

export function Actus(props) {
  const api = ApiClient();

  // const alias = props.match.params.alias;

  const responsive = {
    largerdesktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 1600, min: 992 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const [actus, setActus] = useState([]);

  useEffect(() => {
    fetchActus();
  }, []);

  const fetchActus = async () => {
    api.getAllActus().then((response) => {
      setActus(response.data);
    });
  };

  return (
    <Carousel
      swipeable={true}
      draggable={true}
      autoPlay={true}
      responsive={responsive}
      infinite={true}
      className="Actus-slider"
      autoPlaySpeed={5000}
      removeArrowOnDeviceType={["tablet", "mobile"]}>
      {actus.map((actu, i) => (
        <Actu key={i} actu={actu} />
      ))}
    </Carousel>
  );
}

function Actu(props) {
  return (
    <Card className="card-actus">
      <AutoLink link={"/actualites/" + props.actu.nid}>
      <CardMedia
        component="img"
        height="180"
        image={process.env.REACT_APP_URL_BACK + props.actu.image_actu}
      />
      <CardContent className="flexible">
        <Typography gutterBottom variant="h5" component="div" className="card-title">
          {props.actu.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        <span
            dangerouslySetInnerHTML={{
              __html:
                props.actu.body.length > 230
                  ? props.actu.body.substring(0, 230) + "..."
                  : props.actu.body,
            }}></span>
        </Typography>
      </CardContent>
      <CardActions>   
      </CardActions>
      </AutoLink>
    </Card>
  );
}
