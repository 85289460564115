import React, { useState, useEffect } from "react";
import { ApiClient } from "utils/apiClient";

export function ActusItem(props) {
  const api = ApiClient();

  const [actusItem, setActusItems] = useState([]);

  // const alias = props.match.params.alias;

  useEffect(() => {
    const uri = props.location.pathname;
    const tmp = uri.split("/");
    const node = tmp[tmp.length - 1];
    fetchActusItem(node);
  }, []);

  const fetchActusItem = async (nid) => {
    api.getActu(nid).then((response) => {
      const [actu] = response.data;
      setActusItems(actu);
    });
  };

  return (
    <div className="mb-80 row">
      <h2>{actusItem.title}</h2>
      <img
        alt="img"
        src={process.env.REACT_APP_URL_BACK + actusItem.image_actu}
        className="col-xl-5"
      />
      <div className="col-xl-7">
        <span>{actusItem.date_evenement_export}</span>
        <div
          dangerouslySetInnerHTML={{
            __html: actusItem.body,
          }}></div>
      </div>
    </div>
  );
}
