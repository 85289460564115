import React from "react";

const ParagraphDocument = ({
  item: { id, document, titre_document },
}) => {
  return (
    <div key={id} className="paragraph-documents">
      <ul>
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href={process.env.REACT_APP_URL_BACK + document}>
            {titre_document}
          </a>
        </li>
        </ul>
    </div>
  );
};

export default ParagraphDocument;
