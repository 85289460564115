import React from "react";

const ParagraphFondGris = ({ item: { id, title, body } }) => {
  return (
    <div key={"paragraph" + id} className="txt-fnd-gris mb-5">
      <h2
        className="col-lg-12"
        dangerouslySetInnerHTML={{
          __html: title,
        }}></h2>
      <div
        className="col-lg-12"
        dangerouslySetInnerHTML={{
          __html: body,
        }}></div>
    </div>
  );
};

export default ParagraphFondGris;
