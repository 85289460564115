import { ApiClient } from "utils/apiClient";
import React, { useState, useEffect } from "react";
import { AutoLink } from "components/AutoLink";
import "styles/SideMenu.scss";
import { menuFinder } from "utils/tools";
import { FaAngleRight } from "react-icons/fa";

//component for left sidebar menu
function SideMenu({ location: { pathname }, ...props }) {
  const api = ApiClient();

  // const [items, setItems] = useState([]);
  const [currentElement, setCurrentElement] = useState(null);

  // const alias = props.match.params.alias + props.match.params.alias1;

  useEffect(() => {
    const elements = menuFinder(props.items, pathname.substring(1));
    if (elements && elements[0]?.below) {
      setCurrentElement(elements && elements.length > 1 && elements[0]);
    } else {
      setCurrentElement(elements && elements.length > 1 && elements[1]);
    }
    // fetchItems();
  }, [props.items, pathname]);

  //get response data and insert in item object
  // const fetchItems = async () => {
  //   api.getMenu("main").then((response) => {
  //     setItems(response.data);
  //   });
  // };

  //component render if current element has child
  if (currentElement) {
    return (
      <div className="SideBar">
        <nav className="SideNav">
          <ul className="SideNavlist">
            <div className="SidenavTitle">{currentElement.title}</div>

            {currentElement &&
              currentElement.below?.map((item) => (
                <AutoLink key={item.key} link={item.relative}>
                  <li>
                    <FaAngleRight />
                    &nbsp;
                    {item.title}
                  </li>
                </AutoLink>
              ))}
          </ul>
        </nav>
      </div>
    );
  } else return null;
}

export default SideMenu;
