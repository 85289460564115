import React, {useState, useEffect} from "react";
import {Redirect, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Layout from "components/Layout";
import Page from "./page";
import {ActusItem} from "components/ActusItem";
import {ApiClient} from "utils/apiClient";
import {routesFinder} from "utils/tools";
import NotFound from "utils/NotFound";


const WebPages = () => {
    const [items, setitems]           = useState([]);
    const [pageRoutes, setPageRoutes] = useState([]);
    const api                         = ApiClient();

    useEffect(() => {
        fetchMenu1();
        fetchMenu2();
    }, []);

    useEffect(() => {
        const routes = routesFinder(items);
        setPageRoutes(routes);
    }, [items]);

    const fetchMenu1 = async () => {
        api.getMenu("main").then((response) => {
            setitems((items) => [...items, ...response.data]);
        });
    };

    const fetchMenu2 = async () => {
        api.getMenu("footer2").then((response) => {
            setitems((items) => [...items, ...response.data]);
        });
    };

    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={["/actualites/:alias"]}
                    render={(props) => (
                        <Layout {...props} items={items}>
                            <ActusItem {...props} items={items}/>
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path={[...["/", "/node/:alias", "/:alias"], ...pageRoutes]}
                    render={(props) => (
                        <Layout {...props} items={items}>
                            <Page {...props} items={items}/>
                        </Layout>
                    )}
                />
                <Route component={NotFound} />
            </Switch>
        </Router>

    );
};
export default WebPages;
