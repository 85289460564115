import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ApiClient } from "utils/apiClient";
import "styles/Filieres.scss";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import { SpaTwoTone } from "@mui/icons-material";
import { AutoLink } from "components/AutoLink";

export function Filieres(props) {
  const api = ApiClient();

  // const alias = props.match.params.alias;

  const responsive = {
    largerdesktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1300 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1300, min: 992 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const [filiere, setFilieres] = useState([]);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    api.getFilieres().then((response) => {
      setFilieres(response.data);
    });
  };

  return (
    <Carousel
      swipeable={true}
      draggable={true}
      autoPlay={true}
      keyBoardControl={true}
      responsive={responsive}
      infinite={true}
      className="filiere-slider mb-4"
      autoPlaySpeed={5000}
      removeArrowOnDeviceType={["tablet", "mobile"]}>
      {filiere.map((item, i) => (
        <Filiere key={i} filiere={item} />
      ))}
    </Carousel>
  );
}

function Filiere(props) {
  return (
    <Card className="card-filieres">
      <AutoLink link={props.filiere.view_node}>
      <CardMedia
        component="img"
        height="200"
        image={
         process.env.REACT_APP_URL_BACK + props.filiere.image_filiere
       
        }
      />
      <CardContent className="flexible">
        {/* <Typography gutterBottom variant="h5" component="div">
          {props.filiere.title}
        </Typography> */}

        <Typography variant="body2" color="text.secondary">
          <span
            dangerouslySetInnerHTML={{
              __html: props.filiere.presentation,
            }}></span>
        </Typography>
      </CardContent>
      </AutoLink>
    </Card>
  );
}
