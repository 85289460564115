import React from "react";
import { AutoLink } from "components/AutoLink";

const ParagraphVignette = ({
  item: {
    id,
    couleur_vignette,
    image_vignette,
    texte_vignette,
    texte_du_lien,
    lien,
  },
}) => {
  return (
    <div
      key={"paragraph" + id}
      className={"mb-5 vignette-card col-lg-3 bordure-" + couleur_vignette}>
      <img
        className="img-vignette"
        alt="img"
        src={process.env.REACT_APP_URL_BACK + image_vignette}
      />
      <div
        className="txt-vignette"
        dangerouslySetInnerHTML={{
          __html: texte_vignette,
        }}></div>
      <AutoLink link={lien}>
        <div
          className={"lien-vignette btn-" + couleur_vignette}
          dangerouslySetInnerHTML={{
            __html: texte_du_lien,
          }}></div>
      </AutoLink>
    </div>
  );
};

export default ParagraphVignette;
