import { ApiClient } from "utils/apiClient";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Categories from "components/Categories";
import "styles/Documents.scss";

function Documents() {
  const api = ApiClient();

  const [document, setDocuments] = useState([]);

  useEffect(() => {
    fetchItems();
  }, []);

  const [activeCategory, setActiveCategory] = useState("");
  const categories = document.reduce(
    (acc, elem) =>
      acc.includes(elem.type_de_document)
        ? acc
        : acc.concat(elem.type_de_document),
    []
  );

  const fetchItems = async () => {
    api.getDocuments().then((response) => {
      setDocuments(response.data);
    });
  };

  return (
    <>
      <Categories
        categories={categories}
        setActiveCategory={setActiveCategory}
        activeCategory={activeCategory}
      />
      <div className="fond-documentaire">
        {document.map((item, i) =>
          !activeCategory || activeCategory === item.type_de_document ? (
            <Vignette key={i} document={item} />
          ) : null
        )}
      </div>
    </>
  );
}

function Vignette(props) {
  return (
    <Card className={props.document.couleur + " card-documents"}>
      <a
        target="blank"
        href={process.env.REACT_APP_URL_BACK + props.document.document}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h5">
            <span
              dangerouslySetInnerHTML={{
                __html: props.document.title,
              }}></span>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <span
              dangerouslySetInnerHTML={{
                __html: props.document.body,
              }}></span>
          </Typography>
          {props.document.date_document}
        </CardContent>
      </a>
    </Card>
  );
}

export default Documents;
