import { ApiClient } from "utils/apiClient";
import React, { useState, useEffect } from "react";
import "styles/Partenaires.scss";

function PartenairesList(props) {
  const api = ApiClient();

  const [partenaires, setPartenaires] = useState([]);

  useEffect(() => {
    fetchPartenaires();
  }, []);

  const fetchPartenaires = async () => {
    api.getPartenaires().then((response) => {
      setPartenaires(response.data);
    });
  };

  return (
    <>
      <div className="partenaire-list">
        {partenaires.map((item) => (
          <div key={item.nid} className="partenaire-item">
            <a href={item.lien_partenaire} target="_blank" rel="noreferrer">
              <img
                className="img-partenaires"
                alt="logo"
                src={process.env.REACT_APP_URL_BACK + item.logo_partenaire}
              />
            </a>
          </div>
        ))}
      </div>
    </>
  );
}

export default PartenairesList;
