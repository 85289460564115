import React from "react";
import { MenuBar } from "./MenuBar.jsx";
import styles from "styles/Header.module.scss";

const Header = () => {
  return (
    <header className={styles.headerBar}>
      <MenuBar />
    </header>
  );
};

export default Header;
