import React from 'react';
import {Link} from 'react-router-dom';
import Header from "components/Header";
import Footer from "components/Footer";
import "styles/NotFound.scss";
import PartenairesList from "../components/PartenairesList";
import ScrollArrow from "../components/ScrollArrow";

class NotFoundPage extends React.Component {
    render() {
        return (

            <div className={"PageNotFound"}>
                <Header/>
                    <div className={"PageNotFoundMessage"}>
                        <div>
                            Page non trouvée
                        </div>
                    </div>
                <ScrollArrow/>
                <Footer/>
            </div>
        )
    }
}

export default NotFoundPage;