import React, {useState, useEffect} from "react";
import "styles/Page.scss";
import {ApiClient} from "utils/apiClient";
import {menuFinder, renderParagraph} from "utils/tools";

import {Actus} from "components/Actus";
import {Filieres} from "components/Filieres";
import {Consultations} from "components/Consultations";
import {AllConsultations} from "components/AllConsultations";
import {NewsLetters} from "components/NewsLetters";
import {Evenements} from "components/Evenements";
import Documents from "components/Documents";
import {useMatomo} from '@datapunt/matomo-tracker-react'
import {Link} from "react-router-dom";
import NotFound from "utils/NotFound";

//component for render page contents from Drupal Api
function Page({location: {pathname}, ...props}) {
    const api = ApiClient();

    const {trackPageView} = useMatomo()

    const MainContent = ({children, cssClass}) => {
        return <div className={"mainContent row " + cssClass}>{children}</div>;
    };

    const [item, setItem] = useState(null);

    //change component content when alias change
    useEffect(() => {
        const menuElements = menuFinder(props.items, pathname.substring(1));
        if (menuElements && menuElements.length > 0) {
            const menu = menuElements[0];
            const tmp  = menu.uri.split("/");
            const node = tmp[tmp.length - 1];
            trackPageView();
            fetchItem(node);
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 100);
        }
    }, [props.items, pathname]);

    //Request Drupal Api with node id in parameter
    const fetchItem = async (nid) => {
        api.getContent(nid).then((response) => {
            setItem(response.data[0]);
        });
    };


    if (item) {
        return (<MainContent>
                {pathname === "/" && (<div className="carousel">
                        <Filieres {...props} items={item}/>
                    </div>)}
                {item.presentation && (<div
                        dangerouslySetInnerHTML={{
                            __html: item.presentation,
                        }}
                    />)}
                {item?.paragraphe_export?.map((item) => {
                    return <div className="pdl-90 pdr-90" key={"paragraph" + item.id}>{renderParagraph(item)}</div>;
                })}
                {pathname === "/" && (<div className="col-lg-7 mb-5 pdl-90">
                        <h2 className="text-center">Calendrier des consultations</h2>
                        <Consultations/>
                    </div>)}
                {pathname === "/" && (<div className="carousel col-lg-5 mb-5 pdr-90">
                        <h2 className="text-center">Actualités et Évenements</h2>
                        <Actus {...props} items={item}/>
                    </div>)}

                {pathname === "/fond-documentaire" && <Documents/>}

                {pathname === "/communication/consultations" && <AllConsultations/>}

                {pathname === "/communication/newsletters" && <NewsLetters/>}

                {pathname === "/agenda/webinaires" && <Evenements type="webinaires"/>}
                {pathname === "/agenda/evenements-du-gcs" && (<Evenements type="evenements-du-gcs"/>)}
                {pathname === "/agenda/evenements-partenaires" && (<Evenements type="evenements-partenaires"/>)}
            </MainContent>);
    }
    else {
        return <MainContent>
            <div style={{textAlign: "center"}}>
                <div>
                   Page non trouvée
                </div>

            </div>
        </MainContent>;

    }

}

export default Page;
