import React, { useState, useEffect } from "react";
import { ApiClient } from "utils/apiClient";
import "styles/Consultations.scss";

export function AllConsultations() {
  const api = ApiClient();

  const [consultations, setConsultations] = useState([]);

  useEffect(() => {
    fetchAllConsultations();
  }, []);

  const fetchAllConsultations = async () => {
    api.getAllConsultations().then((response) => {
      setConsultations(response.data);
    });
  };

  return (
    <>
      <h2 className="text-center mb-5">Consultations</h2>
      {consultations.map((item, i) => (
        <Consultation key={i} consultation={item} />
      ))}
    </>
  );
}

function Consultation(props) {
  return (
    <div className="consultation d-flex row">
      <div className="d-flex date-consultation col-lg-4 col-xl-3">
        <span className="jour">{props.consultation.jour}</span>
        <span className="d-flex flex-column mois-annee">
          <span>{props.consultation.mois}</span>
          <span>{props.consultation.annee}</span>
        </span>
      </div>
      <div className="d-flex flex-column content col-lg-8 col-xl-9">
        <span className="titre">{props.consultation.title}</span>
        <span
          className="description"
          dangerouslySetInnerHTML={{
            __html: props.consultation.body,
          }}
        />
      </div>
    </div>
  );
}
