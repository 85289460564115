import React, { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";
import "styles/ScrollArrow.scss";

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 150) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 150) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (showScroll)
    return (
      <>
        {showScroll && (
          <div
            style={{ display: showScroll ? "flex" : "none" }}
            className="scrollBar">
            <span className="scrollCircle">
              <FaAngleUp className="scrollTop" onClick={scrollTop} />
            </span>
          </div>
        )}
      </>
    );
  return null;
};

export default ScrollArrow;
