import React from "react";

const ParagraphTexteGaucheImageDroite = ({ item: { id, body, image } }) => {
  return (
    <div key={"paragraph" + id} className="txt-gch-img-drt mb-5">
      <div
        className="txt-gch-img-drt-body"
        dangerouslySetInnerHTML={{
          __html: body,
        }}></div>
      <img
        className="txt-gch-img-drt-img"
        alt="img"
        src={process.env.REACT_APP_URL_BACK + image}
      />
    </div>
  );
};

export default ParagraphTexteGaucheImageDroite;
