import React, { useState, useEffect } from "react";
import { ApiClient } from "utils/apiClient";
// import PDFViewer from "mgr-pdf-viewer-react";
import "styles/NewsLetter.scss";

export function NewsLetters() {
  const api = ApiClient();
  // Create new plugin instance

  const [newsletters, setNewsLetters] = useState([]);


  useEffect(() => {
    fetchNewsLetters();
  }, []);

  const fetchNewsLetters = async () => {
    api.getNewsLetters().then((response) => {
      setNewsLetters(response.data);
    });
  };

  return newsletters.map((item, i) =>
    i === 0 ? (
      <FirstNewsLetter key={i} item={item} />
    ) : (
      <OldNewsLetter key={i} item={item} />
    )
  );
}



function FirstNewsLetter(props) {
  return (
    <div key={props.item.id}>
      <h2 className="text-center" id="newletterCourante">
        Newsletter en cours
      </h2>
      <div className="pdfViewer">
      <embed
        src={process.env.REACT_APP_URL_BACK + props.item.newsletter_pdf}
        type="application/pdf"
        width="100%"
      />
      
      </div>
      <h2 className="text-center" id="newslettersArchivees">
        Newletters archivées
      </h2>
    </div>
  );
}



function OldNewsLetter(props) {
  return (
    <div key={props.item.id}>
      <ul>
        <li className="link-newsletter">
          <div>{props.item.created}</div>
          &nbsp;
          <a
            target="blank"
            href={process.env.REACT_APP_URL_BACK + props.item.newsletter_pdf}>
            {props.item.title}
          </a>
        </li>
      </ul>
    </div>
  );
}
