import React from "react";

const ParagraphTitreTexte = ({ item: { id, title, body } }) => {
  return (
    <div
      className="col-lg-12 paragraph-titre-texte mb-5"
      key={"paragraph" + id}>
      <div className="title-txt">
        <h2
          id={title
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()}>
          {title}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: body,
          }}></div>
      </div>
    </div>
  );
};

export default ParagraphTitreTexte;
