import axios from "axios";
// import React, { useState, useEffect } from "react";

export function ApiClient() {
  const getData = async (path) => {
   

    //get request on Drupal Api
    var config = {
      method: "get",
      url: process.env.REACT_APP_URL_BACK + path,
    };

    return axios(config);
  };

  //resquest for menus
  const getMenu = async (menu) => {
    return getData("/api/menu_items/" + menu);
  };

  //request for page content
  const getContent = async (nid) => {
    return getData("/page_content/" + nid);
  };

  //request for page content
  const getFilieres = async () => {
    return getData("/filieres_content/");
  };

  //request for actus
  const getAllActus = async () => {
    return getData("/api/actualites");
  };

  const getActu = async (nid) => {
    return getData("/api/actualites/" + nid);
  };

  const getDocuments = async () => {
    return getData("/api/documents");
  };

  //request for page partenaires
  const getPartenaires = async () => {
    return getData("/api/partenaires");
  };

  //request for consultations
  const getConsultations = async () => {
    return getData("/api/consultations");
  };

  //request for consultations
  const getAllConsultations = async () => {
    return getData("/api/allconsultations");
  };

  //request for consultations
  const getNewsLetters = async () => {
    return getData("/api/newsletters");
  };

  //request for consultations
  const getEvenements = async (type) => {
    return getData("/api/" + type);
  };

  return {
    getData,
    getMenu,
    getContent,
    getFilieres,
    getAllActus,
    getActu,
    getDocuments,
    getPartenaires,
    getConsultations,
    getAllConsultations,
    getNewsLetters,
    getEvenements,
  };
}
