import React from "react";

const ParagraphImage = ({ item: { id, image } }) => {
  return (
    <div
      key={"paragraph" + id}
      className="paragraph-images mb-5">
      {image?.map((image) => (
        <img
          key={"img" + id}
          alt="img"
          src={process.env.REACT_APP_URL_BACK + image}
        />
      ))}
    </div>
  );
};

export default ParagraphImage;
